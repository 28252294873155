/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import Editor from '@toast-ui/editor';
import '@toast-ui/editor/dist/toastui-editor.css';

window.Vue = require("vue").default;

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

//Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */


// const app = new Vue({
//     el: '#app',
// });

let editor = null;
if (document.querySelector('#editor')) {
    editor = new Editor({
        el: document.querySelector('#editor'),
        height: '500px',
        initialEditType: 'markdown',
        previewStyle: 'vertical'
    });
}

if (editor && document.querySelector('#template-form')) {
    editor.setMarkdown(document.querySelector('#template').value);

    document.querySelector('#template-form').addEventListener('submit', e => {
        e.preventDefault();
        document.querySelector('#template').value = editor.getMarkdown();
        e.target.submit();
    });
}

if (editor && document.querySelector('#periodsetting-form')) {
    editor.setMarkdown(document.querySelector('#value').value);

    document.querySelector('#periodsetting-form').addEventListener('submit', e => {
        e.preventDefault();
        document.querySelector('#value').value = editor.getMarkdown();
        e.target.submit();
    });
}

window.exportDatatable = function (t, tableName) {
    let text = "\xEF\xBB\xBF";
    let data = t.rows().data().map(function (row) {
        return row.map(function (cell) {
            const res = $('<p>' + cell + '</p>').text();
            return res.replaceAll('"', '').replaceAll("'", '').split(/\s+/).join(' ');
        });

    });
    data.map(row => text += '"' + row.join('";"') + '"\n');


    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', tableName + '-export.csv');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}

window.exportToCSV = function (tableName) {
    // Táblázat kiválasztása
    const dataTable = $('.dataTable').DataTable();

    // Csak a szűrt sorokat választjuk ki
    let rowsFiltered = dataTable.rows({search: 'applied'}).nodes().toArray();

    // Fejlécek kiválasztása
    let header = [];
    $('.dataTable thead th').each(function () {
        header.push('"' + $(this).text().trim() + '"');
    });

    // CSV adatok generálása
    let csv = header.join(';') + '\n';
    for (let row of rowsFiltered) {
        let rowArray = [];
        $(row).find('td').each(function () {
            let text = $(this).text().trim();
            text = text.replace(/"/g, '""'); // idézőjelek duplázása
            text = '"' + text.replace(/\n/g, ' ') + '"'; // újsor karakterek eltávolítása vagy cseréje szóközre
            rowArray.push(text);
        });
        csv += rowArray.join(';') + '\n';
    }

    // CSV fájl letöltése
    let blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csv], {type: 'text/csv;charset=utf-8'});
    let a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = 'table.csv';
    a.click();
}

window.simplePostForm = function (url, method, params) {
    const form = $('<form></form>');
    form.attr("method", method);
    form.attr("action", url);
    $.each(params, function (key, value) {
        if (typeof value == 'object' || typeof value == 'array') {
            $.each(value, function (subkey, subvalue) {
                const field = $('<input />');
                field.attr("type", "hidden");
                field.attr("name", key + '[]');
                field.attr("value", subvalue);
                form.append(field);
            });
        } else {
            const field = $('<input />');
            field.attr("type", "hidden");
            field.attr("name", key);
            field.attr("value", value);
            form.append(field);
        }
    });
    $(document.body).append(form);
    form.submit();
    return false;
}

let table = new DataTable('.data-table', {
    autoWidth: false,
    responsive: true,
    scrollX: true,
    processing: true,
    info: true,
    order: [["0", "desc"]],
    ordering: true,
    searching: true,
    lengthMenu: [50, 100, 500],
    lengthChange: true,
    paging: true,
    stateSave: true,
    fixedColumns: {right: 1},
    columnDefs: [{
        visible: true,
        defaultContent: "-",
        targets: "_all"
    }],
    dom: 'B<"top d-flex justify-content-between"lf>t<"bottom"ip>',
    buttons: [
        'copy', 'csv', 'excel', 'pdf', 'print'
    ],
    language: {
        decimal: "",
        emptyTable: "Nincs találat",
        info: "Mutat: _START_ - _END_ / _TOTAL_ elemből",
        infoEmpty: "",
        infoFiltered: "(szűrve _MAX_ elemből)",
        infoPostFix: "",
        thousands: ",",
        loadingRecords: "Töltés...",
        processing: "Feldolgozás...",
        zeroRecords: "Nincs találat",
        paginate: {
            first: "Első",
            last: "Utolsó",
            next: "Következő",
            previous: "Előző"
        },
        aria: {
            sortAscending: ": activate to sort column ascending",
            sortDescending: ": activate to sort column descending"
        },
        buttons: {
            "copy": 'Másolás',
            "copyTitle": 'Másolás a vágólapra',
            "copySuccess": {
                _: 'Másolva %d sor a vágólapra',
                1: 'Másolva 1 sor a vágólapra'
            },
            "print": 'Nyomtatás',
            "colvis": 'Oszlop láthatóság',
        },
        search: "Keresés",
        lengthMenu: "Mutat: _MENU_ elemet"
    }
});

$('.big-table-show-after-data-table-initialized').show();
$('.big-table-loading-message').hide();

table.buttons().container().appendTo('.dt-length');
$('.dt-buttons').css('display', 'inline-block');

// add a character to .dt-column-order class
$('.dt-column-order').append('<br>');

$(document).ready(function () {
    let currentUrl = window.location.href.split('?')[0];

    $('.nav-item a').each(function () {
        let link = $(this).attr('href');
        if (currentUrl === link || currentUrl.startsWith(link) && link.length > 1) {
            if (link.endsWith('/admin')) {
                return;
            }
            $(this).addClass('active');
            $(this).closest('.nav-item.has-treeview').addClass('menu-open').addClass('active');
            $(this).closest('.nav-item.has-treeview').children('.nav-link').addClass('active');
            $(this).closest('.nav-item.has-treeview > a').addClass('active');
        }
    });

    $('select').each(function() {
        if (!$(this).hasClass('select2-hidden-accessible')) {
            $(this).select2();
        }
    });

    //$('.form-group label + select').not('#paymode, #invoiceNumber').before('<div>&nbsp;</div>');
});

